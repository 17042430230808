import React from "react"
import { Box, Grid, Text, Image } from "theme-ui"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Breadcrumbs from "../components/Breadcrumbs"

import brellImage from "../images/brell/magic-show.jpg"

const BrellPage = () => {
  return (
    <Layout>
      <SEO title="Brell" />


      <Grid variant="base" columns={[1, 2]} sx={{ gridGap: 6 }}>
        <Box>
          <Box sx={{ mx: [-2, -4, -6] }}>
              <Breadcrumbs title="Brell's Magic Industries" headline="Coming soon" />
          </Box>
          <Text as="p" variant="bodyLarge">Brell's Magic Industries makes magic that everyone needs! From our Breezy Brolly to our Magnificent Morsels, there's a Brell for every home.</Text>
          <Text as="p" variant="bodyLarge">Multimedia tie-ins for the Quest Treasure Catalog will be coming soon to this page.</Text>
        </Box>

        <Image src={brellImage} alt="Brell's Consumer Magic Show" />
      </Grid>
    </Layout>
  )
}

export default BrellPage